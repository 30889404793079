import React, {useEffect, useState} from "react";
import axios from "axios";

const baseURL = `https://admin.drmuizzu.mv/api/achievements?lang=en`

export default function Achievements(){
    const [RecvData, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      axios.get(baseURL).then((resp) =>{
          setData(resp.data)
          setLoading(true)
      })
    }, []);
  
    if(!RecvData) return null;


    return (
        <section className="max-w-screen-xl lg:mr-auto lg:ml-auto font-faruma text-xl mt-20 mb-20" >
            <h3 className="text-2xl text-muizzuMvDB font-poppins font-bold pl-4 lg:text-center ">Achievements</h3>
            <div className="lg:flex lg:flex-wrap mt-10 justify-between lg:text-justify">
            {
                loading && RecvData['achievements'].map((achievement)=>{
                    return <div key={achievement.id} className="pl-4 pr-4 lg:pl-0 lg:pr-0 lg:min-w-fit  mt-10 lg:mb-10 lg:mt-0">
                    <h3 className="text-muizzuMvDB font-poppins font-bold text-[1.3rem] pb-2 mt-5" >{achievement.title_en}</h3>
                    <h3 className="text-muizzuMvLB font-poppins opacity-80 pb-5" >{achievement.date_en}</h3>
                    <p className="max-w-md font-poppins ml-auto mb-5">{achievement.text_en}</p>
                </div>
                })
            }
            </div>
        </section>
    )
}