import Navbar from "../Components/NavbarDhi";
import DaysLifeDhi from "../DhiComponents/DaysLifeDhi";
import LifeHero from "../DhiComponents/LifeHero";
import LifeMainContent from "../DhiComponents/LifeMainContent";
import Footer from '../Components/Footer'
import axios from "axios";
import { useState,useEffect } from "react";
import Loading from "../Components/Loading";

const baseUrl = "https://admin.drmuizzu.mv/api/posts"


export default function LifeDhi(){
    const [posts, setPosts] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        async function fetchData() { 
            try{
                const request = await axios.get(baseUrl)
                setPosts(await request.data.posts)
                setLoading(true)
                return request

            }catch(error){
                console.log(error)
            }
             
             
     }
     fetchData()

     },[])

    if(loading){
    return (
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
                <Navbar/>
                <LifeHero 
                posts={posts}
                loading={loading}
                />
                {/* <LifeMainContent/> */}
                <DaysLifeDhi/>
            </div>
            <div>
                <Footer/>
            </div>
            
        </section>
       
    )}else{
        return (<Loading/>)
    }
}