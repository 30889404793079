import { click } from "@testing-library/user-event/dist/click"
import { useState, useEffect, Fragment, useReducer, useCallback } from "react"
import { Link } from "react-router-dom"

export default function LifeHero(props){
    const [posts, setPosts] = useState(props.posts)
    const [lifePosts, setLifePost] = useState()
    const [currPost, setCurrPost] = useState()
    const [loading, setLoading] = useState(props.loading)
    const [index, setIndex] = useState(0)
    const [yearPost, setYearPost] = useState()
    const [clicked, setClicked] = useState({year:0,clicked:false})
    let duplicatecheck = []

    useEffect(()=>{ 
        setLifePost(() => posts.filter((post) => post.model_type === 'life'))
    },[])
    
    useEffect(()=>{
        if(lifePosts && !clicked.clicked){
            setCurrPost(lifePosts[index])
        }
        else if(clicked && yearPost){
            setCurrPost(yearPost[index])
        }
        
    },[lifePosts,index,clicked,yearPost])

    useEffect(()=>{
        if(clicked.clicked && lifePosts){
            setYearPost(()=>lifePosts.filter((post)=>{return parseInt(post.year) === parseInt(clicked.year)}))
        }
    },[clicked])
    
    function nextArticle(e){
        if(lifePosts && index < lifePosts.length -1 && !clicked.clicked){
            setIndex((prev)=>prev + 1)
        }
        else if(clicked && index < yearPost.length -1){
            setIndex((prev)=>prev + 1)
        }
    }

    function prevArticle(){
        if(lifePosts && index > 0 && !clicked.clicked){
            setIndex((prev)=>prev - 1)
        }
        else if(clicked && index > 0){
            setIndex((prev)=>prev - 1)
        }
    }

    function findArticle(event){
        setClicked({year:parseInt(event.target.innerHTML),clicked:true})

    }

    if(currPost){
       
    return(
        <Fragment>


        <section dir="rtl" className="max-w-screen-xl lg:mr-auto lg:ml-auto lg:flex lg:flex-row font-faruma text-xl lg:mt-20 mt-5 flex flex-col-reverse">
        <div className="lg:max-w-[36rem] lg:min-w-[36rem] lg:max-h-[25rem] lg:min-h-[25rem] lg:ml-20 mb-5 mr-5" >
                <Link to={`${currPost.id}`}><p className="text-muizzuMvLB mb-5 mt-2 font-aamu text-2xl">{currPost && currPost.year}</p></Link>
                <Link to={`${currPost.id}`}><h3 className="text-muizzuMvDB lg:max-h-13 mb-5 font-aamu lg:leading-10 leading-10 lg:text-2xl" dangerouslySetInnerHTML={{__html:currPost && currPost.title_dv}}></h3></Link>
                <div className=" lg:max-h-[12rem] pl-4 lg:pl-0 lg:min-h-[12rem] max-h-[14rem] min-h-[12rem] overflow-scroll">
                <Link to={`${currPost.id}`}><p className="text-muizzuMvGray font-faruma" dangerouslySetInnerHTML={{__html:currPost && currPost.body_dv}}></p></Link>
                </div>
            <div className="mt-auto flex gap-4">
                <button onClick={nextArticle} id='next' className="border-solid border-black border-2 rounded-xl pl-3.5 pr-3.5 pt-2 pb-2 text-lg hover:bg-muizzuMvDB hover:text-white">ކުރުއަށް ←</button>
                <button onClick={prevArticle} id='prev' className="border-solid border-black border-2 rounded-xl pl-3.5 pr-3.5 pt-2 pb-2 text-lg hover:bg-muizzuMvDB hover:text-white">→ ފަހަތަށް</button>
            </div>
        </div>
        <div className="bt-5 ">
           <Link to={`${currPost.id}`}> <img className="object-cover lg:max-w-[40rem] lg:min-w-[40rem] lg:max-h-[25rem] lg:min-h-[25rem] px-4" src={currPost && `https://admin.drmuizzu.mv${currPost.thumbnail}`} alt="heropp-img"/></Link>
        </div>  
        
        </section>
        <div className="max-w-screen-xl lg:flex lg:flex-row flex lg:mr-auto lg:ml-auto font-faruma text-xl mt-20 overflow-auto lg:gap-8 gap-4 justify-center text-center text-muizzuMvGray">
            {
                
                loading && posts.map((post)=>{
                    let found = duplicatecheck.find(element => element === post.year)
                    if(post.model_type === 'life' && !found){
                        duplicatecheck.push(post.year)
                        return <Link className="hover:text-muizzuMvLB" key={post.id} to='' onMouseDown={findArticle}>{post.year}</Link>
                    }
                })
            }    
            </div>
        </Fragment>
    )}
}