import NavbarEng from "../Components/NavbarEng";
import Footer from '../Components/Footer'
import LifeHeroEng from "../EngComponents/LifeHeroEng";
import LifeMainContentEng from "../EngComponents/LifeMainContentEng";
import DaysInLifeEng from "../EngComponents/DaysInLifeEng";
import axios from "axios";
import { useState,useEffect } from "react";
import Loading from "../Components/Loading";

const baseUrl = "https://admin.drmuizzu.mv/api/posts"

export default function LifeEng(){
    const [posts, setPosts] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        async function fetchData() { 
            try{
                const request = await axios.get(baseUrl)
                setPosts(await request.data.posts)
                setLoading(true)
                return request

            }catch(error){
                console.log(error)
            }
             
             
     }
     fetchData()

     },[])

    if(loading){
    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
            <NavbarEng/>
        <LifeHeroEng
        posts={posts}
        loading={loading}
        />
        {/* <LifeMainContentEng/> */}
        <DaysInLifeEng/>
            </div>
            <div>
                 <Footer/>

            </div>

        </section>
    )}
    else{
        return (<Loading/>)
    }
}