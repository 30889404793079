import { Fragment } from "react";
import Footer from "../Components/Footer";
import NavbarEng from "../Components/NavbarEng";
import NewsEnInfo from "../EngComponents/NewsEnInfo";

export default function News(){
    return(
        <section className='flex flex-col min-h-[100vh]'>
        <div className='pb-10 flex-1'>
        <NavbarEng/>
        <NewsEnInfo/>
        </div> 
        <div>
        <Footer/>
        </div>
        </section>
    )
}