import { Navigate, useParams } from "react-router-dom"
import { Link } from 'react-router-dom';
import axios from "axios"
import Moment from 'moment';
import { useState, useEffect } from "react"
import NavbarDhi from "../Components/NavbarDhi";
import Footer from "../Components/Footer";
import Calender from "../Assets/Calendar.svg"
import Error from "../EngPages/Error"
import './NewsMoreInfo.css'


const baseUrl = `https://admin.drmuizzu.mv/api/posts`

export default function NewMoreInfo(){
    const param = useParams()
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        async function fetchData() { 
            const request = await axios.get(baseUrl)
            setPosts(request.data.posts)
            setLoading(true)
            return request
    }
    fetchData()

    },[])

    
    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
                <NavbarDhi/>
                <section className="lg:flex justify-center">
                    {
                        loading && posts.map((post)=>{
                            
                            if(post.id === parseInt(param.id)){
                                
                        
                                const result = post.body_dv.replace(/(<blockquote.*?>)(.*?)(<\/blockquote>)/g, function (match, bqStart, bqContent, bqEnd) {
                                    return bqStart + bqContent.replace(/class=".*?"|style=".*?"|margin:.*?;|padding:.*?;/g, "") + bqEnd;
                                });
                                
                               

                                return(
                                    <div dir="rtl" key={post.id} className="lg:max-w-screen-xl px-2 lg:px-0 lg:flex lg:flex-col justify-center mt-12">
                                             <div className="lg:max-w-[50rem]">
                                                <div className='flex flex-row mb-3.5 mr-2 mt-2'>
                                                    <p dir="ltr" className='font-poppins text-xs text-muizzuMvGray mt-auto'>{Moment(post.published_at).format('DD MMM YYYY')}</p>
                                                </div>
                                                <h3 className="font-aamu text-[1.920rem] leading-10 text-muizzuMvDB mb-3.5 px-2" dangerouslySetInnerHTML={{__html:post.title_dv}}></h3>
                                                {post.sub_title_dv && <h3 className="font-aamu mb-5 text-xl text-muizzuMvDB opacity-90 px-2 " dangerouslySetInnerHTML={{__html:post.sub_title_dv}}></h3>}
                                             </div>
                                        <img className="object-cover lg:max-w-[50rem] lg:min-w-[50rem] lg:max-h-[27rem] lg:min-h-[27rem] min-w-full px-2" src={`https://admin.drmuizzu.mv${post.thumbnail}`} alt="article Img"/>
                                        <div className="lg:max-w-[50rem] font-faseyha text-[1.311rem] text-[#333333] leading-10 pt-5 px-2 "dangerouslySetInnerHTML={{__html:result}}>
                                           
                                        </div>
                                    </div>
                                )
                            }
                        }
                        )

                        

                    }
                    
                </section>
            </div>
            <div>
                <Footer/>
            </div>
        
        </section>
    )
}