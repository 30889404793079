import Moment from 'moment';
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {animated, useSpring} from 'react-spring'
import axios from "axios";

const baseURL = `https://admin.drmuizzu.mv/api/home`

export default function MainContentDhi(props){
    const [recvPost, setPosts] = useState(props.posts.data)
    const [loading, setLoading] = useState(recvPost? true:false)
    const [currPost,  setCurrPost] = useState()
    const [featuredPosts, setFeaturedPosts] = useState([]);
    const [animation, setAnimation] = useSpring(() => ({opacity: 0}))
    const [page, setPage] = useState(2)
    const [lastPage, setLastPage] = useState(0)
    const [paginate, setPaginate] = useState(true)

    const filterFeaturedPosts = (data) => {
        const filteredData = data.filter((post) => post.is_featured === 1);
        setFeaturedPosts(filteredData);
    }

    async function loadMore() { 
        setPage(page+1)
        if (true) {
          const request = await axios.get(baseURL+`?page=${page}`)
          setLastPage(await request.data.posts.last_page)
          if(page == lastPage) {
            setPaginate(false)
          }
          setPosts(recvPost.concat(await request.data.posts.data))
        }
         
    }


    useEffect(()=>{
        filterFeaturedPosts(recvPost);
    },[recvPost])

    useEffect(()=>{
        if(featuredPosts.length>0){
            setCurrPost(featuredPosts[0])
            setAnimation({ opacity: 1 });
        }
    },[featuredPosts])

    useEffect(()=>{
        
          let count = 0;
    const interval = setInterval(() => {
        if (count < featuredPosts.length - 1) {
            setTimeout(() => {
                setAnimation({ opacity: 0, from: { opacity: 1 }, config: { duration: 500 } });
                setTimeout(() => {
                    count = count + 1
                    setCurrPost(featuredPosts[count])
                    setAnimation({ opacity: 1, from: { opacity: 0 }, config: { duration: 500 } });
                }, 500);
            }, 4000);
        } else {
            count = 0
        }
    }, 4000);
    return () => clearInterval(interval);
      

    },[featuredPosts])



    const articletypecheck =(model_type) =>{if(model_type === 'articles'){return 'ލިޔުން' }else{return 'ޚަބަރު'}}

    const url = (type, id) => {if (type == 'life') {return `/life/${id}`} else {return `/news/${id}`}}

    return(
        <section dir='rtl' className="max-w-screen-xl lg:mr-auto lg:ml-auto flex flex-col lg:flex-row items-center lg:items-start font-faruma text-xl mt-20" >
            
                <animated.div style={animation}  className={`lg:ml-16 mb-20 lg:max-h-[55rem] lg:overflow-hidden`} >
                       <Link to={`/article/${currPost && currPost.id}`}><img   src={currPost && `https://admin.drmuizzu.mv${currPost && currPost.thumbnail}`} className={`px-4 object-cover lg:max-w-[60rem] lg:min-w-[60rem] lg:max-h-[30rem] lg:min-h-[30rem]`} alt='main-content-img' loading="lazy"/></Link>
                       <div className='flex flex-row mt-2 px-4'>
                 
                            <p dir="ltr" className={` font-poppins text-muizzuMvGray text-[0.89rem] mt-auto `}>{currPost && Moment(currPost.published_at).format('DD MMM YYYY')}</p>
                        </div> 
                        <Link to={`/article/${currPost && currPost.id}`}><h3 className={` font-aamu lg:max-w-lg lg:max-h-[10rem] lg:overflow-hidden px-4 text-3xl mt-2 lg:px-2 text-muizzuMvDB`}>{currPost && currPost.title_dv}</h3></Link>
                    

                </animated.div>

            <div >
                <h3 className='text-2xl font-aamu mb-5  text-muizzuMvDB pl-5 pr-5 lg:pl-0 lg:pr-0'>ލިޔުންތައް</h3>

                <div className="overflow-scroll mx-4 lg:mx-0 lg:max-h-[40rem] max-h-[40rem] scrollbarhidden">

                    {
                       loading && recvPost.map((post, index)=>{
                        
                        if(post.dv){
                            if(post.model_type === 'articles' || post.model_type === 'news' ){
                                
                                return <div key={post.id} className=' lg:flex mb-8 '>
                                    <Link to={url(post.model_type, post.id)}><img className='mr-auto object-cover ml-auto lg:max-w-[16rem] lg:min-w-[16rem]  lg:max-h-[13rem] lg:min-h-[13rem]  min-w-[100%]  min-h-[18rem] max-h-[18rem]  lg:pl-0 lg:pr-0' src={`https://admin.drmuizzu.mv/${post.thumbnail}`} alt='main-content-img1'/></Link>    
                                    <div>
                                        <h5 className='text-base text-muizzuMvLB font-aamu mt-5 lg:mt-0 lg:mr-5 mb-3 mr-5'>{articletypecheck(post.model_type)}</h5>
                                        <Link to={url(post.model_type, post.id)}><h3 className='lg:mr-5 font-aamu mb-3 text-2xl  pl-5 pr-5 lg:pl-0 lg:pr-0 text-muizzuMvDB'>{post.title_dv}</h3></Link>
                                        <div className='flex flex-row mr-5'>
                                            {/* <img className='ml-2 ' src={Calender} alt='icon'/> */}
                                         <p dir="ltr" className=' text-muizzuMvGray font-poppins text-[0.89rem] mt-auto'>{Moment(post.published_at).format('DD MMM YYYY')}</p>
                                        </div>
                                        <Link to={url(post.model_type, post.id)}><button className='border-solid border-black border-2 rounded-xl pl-3.5 pr-3.5 pt-2 pb-2 text-lg lg:mr-5 lg:mt-5 mr-5 mt-8 hover:bg-muizzuMvDB hover:text-white'>އިތުރަށް ވިދާޅުވުމަށް ←</button></Link>
                                    </div>
                                </div>
                            }
                        }else{return console.log('No Language article published')}
                            
                            
                        })
                    }
                    <div className=" flex justify-center items-center mt-5">
                    { paginate && <Link className='font-aamu border-solid border-black border-2 rounded-xl px-5 py-2' onClick={loadMore}>އިތުރު ލިޔުންތައް</Link>}
                    </div>
            </div>


            </div>

        </section>
    )
}

