import React, {useEffect, useState} from "react";
import axios from "axios";

const baseURL = `https://admin.drmuizzu.mv/api/achievements?lang=dv`

export default function UsaliDhi(){

    const [RecvData, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      axios.get(baseURL).then((resp) =>{
          setData(resp.data)
          setLoading(true)
      })
    }, []);
  
    if(!RecvData) return null;

    return(
        <section dir='rtl' className="max-w-screen-xl lg:mr-auto lg:ml-auto font-faruma text-xl mt-20" >
            <h3 className="text-2xl text-muizzuMvDB font-aamu text-center lg:text-center">އުސްއަލިތަށް</h3>
            <div className="lg:flex lg:flex-wrap mt-10 justify-between lg:text-justify lg:px-[6rem]">
                {
                    loading && RecvData['achievements'].map((achievement)=>{
                        return <div key={achievement.id} className="pl-5 pr-5 lg:pl-0 lg:pr-0 lg:min-w-fit  mt-10 lg:mb-10 lg:mt-0">
                        <h3 className="font-aamu text-muizzuMvDB text-[1.3rem] pb-2 mt-5" >{achievement.title}</h3>
                        <h3 className=" text-[1rem] text-muizzuMvGray opacity-80 pb-5" >{achievement.date}</h3>
                        <p className="max-w-md mr-auto ml-auto mb-5">{achievement.text}</p>
                    </div>
                    })
                }
            </div>
        </section>
    )
}