

export default function ContactUs(){

    return(
        <section dir="rtl" className="max-w-screen-xl mr-2 ml-2 lg:justify-center font-faruma text-xl lg:flex text-muizzuMvGray lg:mr-auto lg:ml-auto lg:mt-12">
           <div dir="rtl" className="lg:flex lg:flex-row gap-60">
                <div>
                    <h3 className="text-muizzuMvDB font-aamu">އޮފީސް</h3>
                    <p>މާލެ، ދިވެހިރާއްޖެ</p>
                    <p>މާލެ، ދިވެހިރާއްޖެ</p>
                </div>
                <div>
                    <h3 className="text-muizzuMvDB font-aamu">ގުޅުއްވުމަށް</h3>
                    <p>7000000</p>
                    <p>3000000</p>
                </div>
                <div>
                    <h3 className="text-muizzuMvDB font-aamu">މެއިލް</h3>
                    <p>mail@dhivehimail.mv</p>
                    <p>mail@dhivehimail.mv</p>
                </div>
           </div>
           <div className="min-h-screen">

            

           </div>
        </section>
    )
}