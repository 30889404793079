import Comma from '../Assets/“.svg'

export default function BioThaarafContent(props){

    return (
        <section dir='rtl' className="max-w-screen-xl lg:flex  lg:mr-auto lg:ml-auto flex flex-col items-center font-faruma text-xl mt-20" >
            {/* <div className='lg:mr-auto lg:ml-auto pl-5 pr-5'>
                <img className='' src={Comma} alt='quote-comma-img'/>
                <p className='lg:max-w-3xl leading-10 lg:pr-10 font-aamu text-2xl text-center text-muizzuMvDB'>މާލޭ ސިޓީގެ ރައްޔިތުންގެ ޔުނިވަރސިޓީން ގްރެޖުއޭޓްވެ، ރައްޔިތުންގެ ތޮފި އަޅުވައި
، އިންތިޚާބްކުރި ފުރަތަމަ މޭޔަރުގެ ޝަރަފާއެކު ތާރީޚްގެ ތެރެއަށް ވަދެވަޑައިގަތް</p>
            </div> */}
            <div className='mt-10 pr-5 pl-5'>
                <p className='lg:max-w-5xl text-center text-muizzuMvGray'dangerouslySetInnerHTML={{__html:props.detail}} ></p>
            </div>
        </section>
    )
}