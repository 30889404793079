import { useParams } from "react-router-dom"
import { Link } from 'react-router-dom';
import axios from "axios"
import { useState, useEffect } from "react"
import NavbarEng from "../Components/NavbarEng";
import Footer from "../Components/Footer";
import Calender from "../Assets/Calendar.svg"
import Moment from 'moment'
import "./NewMoreInfoEn.css"




const baseUrl = `https://admin.drmuizzu.mv/api/posts`

export default function NewsMoreInfoEn(){
    const param = useParams()
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        async function fetchData() { 
            const request = await axios.get(baseUrl)
            setPosts(request.data.posts)
            setLoading(true)
            return request
    }
    fetchData()
    if(!posts){
        setLoading(false) 
    }else(setLoading(true))
    },[])

    
    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
            <NavbarEng/>
        <section className="lg:flex lg:justify-center">
            {
                loading && posts.map((post)=>{
                    if(post.id === parseInt(param.id)){
                        return(
                            <div key={post.id} className="lg:max-w-screen-xl lg:flex lg:flex-col px-2 lg:px-0 justify-center items-center mt-12">
                                <div className="lg:max-w-[50rem] mr-auto px-2">
                                    <div className='flex flex-row mb-5'>
                                        <p className='font-poppins text-xs text-muizzuMvGray'>{Moment(post.published_at).format('DD MMM Y')}</p>
                                    </div>
                                    <h3 className="font-circular font-bold text-xl text-muizzuMvDB mb-5" dangerouslySetInnerHTML={{__html:post.title}}></h3>
                                    {post.sub_title && <h3 className="font-circular mr-auto mb-5 text-xl opacity-80 text-muizzuMvDB" dangerouslySetInnerHTML={{__html:post.sub_title}}></h3>}
                                </div>
                                <img className="object-cover lg:max-w-[50rem] lg:min-w-[50rem] lg:max-h-[27rem] lg:min-h-[27rem] min-w-full px-2" src={`https://admin.drmuizzu.mv${post.thumbnail}`} alt="article Img"/>
                                <div className="lg:max-w-[50rem] mr-auto mt-2">
                                    <p className="font-poppins pt-5 px-2" dangerouslySetInnerHTML={{__html:post.body}}></p>
                                </div>
                            </div>
                        )
                    }
                })

            }
        </section>
            </div>
            <div>
                <Footer/>
            </div>
        
        </section>
    )
}