
import testImg from '../Assets/MaleRoad.jpeg'
import Calender from "../Assets/Calendar.svg"
import { Link } from 'react-router-dom';
import axios from "axios"
import { useState, useEffect } from "react"
import { type } from "@testing-library/user-event/dist/type"
import Moment from 'moment'


const baseUrl = `https://admin.drmuizzu.mv/api/posts`


export default function NewsEnInfo(){
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        async function fetchData() { 
            const request = await axios.get(baseUrl)
            setPosts(request.data.posts)
            setLoading(true)
            return request
    }
    fetchData()
    if(!posts){
        setLoading(false) 
    }else(setLoading(true))
    },[])


    return (
        <>
            <h1 className='font-poppins lg:hidden font-extrabold mt-5 text-2xl text-center text-muizzuMvDB'>Articles</h1>
      <section className="max-w-screen-xl flex justify-evenly flex-wrap lg:mr-auto lg:ml-auto mt-10">

        {
            loading && posts.map((post)=>{
                if(post.model_type === "news" || post.model_type === "articles"){
                    if(post.en === 1){
                        return (
                            <div key={post.id} className="w-full rounded-lg lg:max-w-sm mb-10 px-3.5">
                                <Link  to={`/articles/en/${post.id}`}><img
                                    className="object-cover w-full lg:max-w-[21rem] lg:min-w-[21rem] lg:max-h-[13rem] lg:min-h-[13rem] h-48"
                                    src={`https://admin.drmuizzu.mv${post.thumbnail}`}
                                    alt="image"
                                /></Link>
                                <div className='flex flex-row mt-2 mb-2'>
                                            {/* <img className='mr-2' src={Calender} alt='icon'/> */}
                                            <p className='font-poppins text-muizzuMvGray text-[0.85rem] mb-auto mt-auto'>{Moment(post.published_at).format('DD MMM Y')}</p>
                                        </div>
                                <div className="">
                                <Link  to={`/articles/en/${post.id}`}><h4 className="text-xl tracking-wide font-circular lg:max-w-[21rem] font-bold text-muizzuMvDB">
                                    {`${post.title.slice(0,50)}`}
                                    </h4></Link>
                            
                                    <Link  to={`/articles/en/${post.id}`}><button className='lg:visible invisible border-solid border-black border-2 rounded-xl pl-3.5 pr-3.5 pt-2 pb-2 mt-2 text-lg  hover:bg-muizzuMvDB hover:text-white'>More Info</button></Link>
                                </div>
                            </div>
                            )
                    }
                    
                }
            })
        }

       
      </section>
      </>
    );
}