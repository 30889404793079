import HeroP from '../Assets/ArthorsPP.png'


export default function BioIntroEng(props){

    return(
        <section  className='max-w-screen-xl lg:max-h-96 mr-4 ml-4 lg:justify-center font-poppins text-xl lg:flex text-muizzuMvGray lg:mr-auto lg:ml-auto lg:mt-12 mt-12'>
        <div className='lg:max-w-2xl text-center lg:text-left pl-5 pr-5 mt-auto mb-auto'>
            <h3 className='font-poppins font-extrabold text-muizzuMvDB text-2xl pb-3'>Introduction</h3>
            <h3 className='font-poppins font-extrabold text-muizzuMvLB text-2xl pb-2'>{props.title}</h3>
            <p dangerouslySetInnerHTML={{__html:props.detail}}></p>
        </div>
        <img className='lg:max-w-lg object-cover ml-auto mt-5 ' src={HeroP} alt='thaaraf-img' />
    </section>
    )
}