import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import SectionImg from '../Assets/Drmuiz.jpeg'
import Footer from '../Components/Footer'
import NavbarEng from '../Components/NavbarEng'
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Pagination} from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const baseURL = `https://admin.drmuizzu.mv/api/home`;


export default function GoalsMoreInforEng(){

  const [RecvData, setData] = useState();
  const [RecvMorePhoto, setMorePhoto] = useState();
  const [currentImgIndex, setCurrentImgIndex] = useState();
  const [currentImgTitle, setCurrentImgTitle] = useState()
  const [loading, setLoading] = useState(false)
  const [goalInfo, setGoalInfo] = useState()
  const [dynTitle, setDynTitle] = useState()
  const [currGoal, setGoal] = useState();
  const param = useParams();

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(baseURL);
      setData(request.data);
      return request;
    }
    fetchData();
    if (!RecvData) {
      setLoading(false);
    } else setLoading(true);

    axios.get(`https://admin.drmuizzu.mv/api/sliders?type=${param.name}`).then((resp)=>{
      
      setMorePhoto(resp.data.sliders)
    })
  }, []);

  useEffect(()=>{
 
    const findTitle = () =>{
      let newTitle = ''
      if(param.name === 'policy'){
        newTitle = 'Policy'
        setDynTitle(newTitle)
      }
      else if(param.name === 'views'){
        newTitle = 'Views'
        setDynTitle(newTitle)
      }
      else if(param.name === 'foresight'){
        newTitle = 'Foresight'
        setDynTitle(newTitle)
      }
    }

    const goalInfo =()=>{
      if(RecvData && param.name === "policy"){
        setGoal(RecvData.intro.policy_en)
      }
      else if (RecvData && param.name === "views")
      {
        setGoal(RecvData.intro.views_en)
      }
      else if(RecvData && param.name === "foresight"){
        setGoal(RecvData.intro.foresight_en)
      }
    }

    goalInfo()

    findTitle()
  },[RecvData,param])
  
  if (!RecvData) return null;
  if (!RecvMorePhoto) return null;



    return (
        <section className='flex flex-col min-h-[100vh]'>
          <div className='pb-5 flex-1'>
          <NavbarEng/>
        <section className="max-w-screen-xl mt-12 font-faruma lg:mr-auto lg:ml-auto" >
        <h3 className="font-poppins font-extrabold text-muizzuMvDB text-2xl mb-5 text text-center "> {dynTitle && dynTitle}</h3>  
        <Swiper 
          modules={[Navigation,Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          onSlideChange={(swiper) => setCurrentImgIndex(swiper.imagesToLoad[swiper.activeIndex].id)}  
          onSwiper={(swiper) => setCurrentImgIndex(swiper.imagesToLoad[swiper.activeIndex].id)}
          className="lg:max-w-[55rem] lg:min-w-[55rem] lg:max-h-[30rem] px-4 lg:px-4 lg:min-h-[30rem] object-cover"
        >
          {RecvMorePhoto.map((element) =>{
              if(element.status === 'active'){
                return <SwiperSlide  key={element.id}><img id={element.id} key={element.id}  src={`https://admin.drmuizzu.mv${element.thumbnail}`} alt="section-img" /></SwiperSlide>
              }
          })}
        </Swiper> 
        <div className='flex lg:max-w-[55rem] lg:mr-auto lg:ml-auto mt-5 px-4 lg:px-0 font-poppins'>
          <p  className="lg:max-w-4xl text-left lg:text-left lg:pr-0 lg:pl-0 " dangerouslySetInnerHTML={{__html:currGoal && currGoal}}></p>
        </div>

      </section>
          </div>
          <div>
            <Footer/>
          </div>
        </section>
        
        )
}
