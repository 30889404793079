import Footer from "../Components/Footer";
import NavbarDhi from "../Components/NavbarDhi";
import ContactUs from "../DhiComponents/ContactUsDhi";


export default function ContactUsDhi(){

    return(
        <>
        <NavbarDhi/>
        <ContactUs/>
        <Footer/>
        </>
    )
}