import React, { useState, useEffect} from "react";
import { TwitterTimelineEmbed,TwitterTweetEmbed } from 'react-twitter-embed';
import axios from "axios";

const baseURL = `https://admin.drmuizzu.mv/api/tweets`

export default function Tweets(){
    const [RecvData, setData] = useState();
    const [loading, setLoading] = useState(false)
  
    useEffect(() => {
      async function fetchData() { 
        try{
            const request = await axios.get(baseURL)
            setData(await request.data.tweets)
            setLoading(true)
            return request
  
        }catch(error){
            console.log(error)
        }
         
         
      }
        fetchData()
  
      }, []);
    
        
      

    if(loading){
    return(
        <section className="max-w-screen-xl px-4 lg:px-0 lg:mr-auto lg:ml-auto font-aamu mt-20 mb-20 lg:justify-between lg:flex">
        <div className='lg:w-[600px]'>
            <TwitterTimelineEmbed 
                sourceType="profile" 
                screenName="MMuizzu"
                options={{height: 600}} 
                
            />
        </div>
        <div className="lg:w-[40%] sm:w-auto mt-8 lg:mt-0 ">
        <h3 className='text-2xl font-aamu mb-5  text-muizzuMvDB pl-5 pr-5 text-right lg:pl-0 lg:pr-0'>މުހިންމު ޓުވީޓްތައް</h3>
        <div className="w-auto overflow-scroll max-h-[550px] rounded-sm">
            {RecvData.map((tweet)=>{
                let tweetId = tweet.link.split("/").pop();
                return (<TwitterTweetEmbed key={tweetId}
                    tweetId={tweetId}
                    
                />)

            })}
        </div>
        </div>
        </section>
    )
}
else{
    console.log('loading')
}
}