
import Comma from "../Assets/“.svg"
import ArthoPP from "../Assets/ArthorsPP.png"
// import MCImg1 from '../Assets/mainContentImg1.svg'
import Calender from "../Assets/Calendar.svg"
import axios from "axios"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Moment from 'moment'
import {animated, useSpring} from 'react-spring'

const baseURL = `https://admin.drmuizzu.mv/api/home`


export default function MainHomeContentEng(props){
    const [recvPost, setPosts] = useState(props.posts.data)
    const [featPosts, setFeatPosts] = useState([])
    const [loading, setLoading] = useState(recvPost? true:false)
    const [currPost,  setCurrPost] = useState([])
    const [animation, setAnimation] = useSpring(() => ({opacity: 0}))
    const [page, setPage] = useState(2)
    const [lastPage, setLastPage] = useState(0)
    const [paginate, setPaginate] = useState(true)

    const filterFeaturedPosts = (data) => {
      const filteredData = data.filter((post) => post.is_featured && post.en);
      setFeatPosts(filteredData);
  }

     async function loadMore() { 
        setPage(page+1)
        if (true) {
          const request = await axios.get(baseURL+`?page=${page}`)
          setLastPage(await request.data.posts.last_page)
          if(page == lastPage) {
            setPaginate(false)
          }
          setPosts(recvPost.concat(await request.data.posts.data))
        }
         
    }

  useEffect(()=>{
      filterFeaturedPosts(recvPost);
  },[recvPost])

  useEffect(()=>{
      if(featPosts.length>0){
          setCurrPost(featPosts[0])
          setAnimation({ opacity: 1 });
      }
  },[featPosts])

  useEffect(()=>{
      

        let count = 0;
  const interval = setInterval(() => {
      if (count < featPosts.length - 1) {
          setTimeout(() => {
              setAnimation({ opacity: 0, from: { opacity: 1 }, config: { duration: 500 } });
              setTimeout(() => {
                  count = count + 1
                  setCurrPost(featPosts[count])
                  setAnimation({ opacity: 1, from: { opacity: 0 }, config: { duration: 500 } });
              }, 500);
          }, 4000);
      } else {
          count = 0
      }
  }, 4000);
  return () => clearInterval(interval);
    

  },[featPosts])

       
  

    const articletypecheck =(model_type) =>{if(model_type === 'articles'){return 'Article' }else{return 'News'}}

    const url = (type, id) => {if (type == 'articles') {return `/en/article/${id}`} else {return `/en/article/${id}`}}
    

    return (
      <section className="max-w-screen-xl lg:mr-auto lg:ml-auto flex flex-col lg:flex-row items-center lg:items-start font-poppins font-extralight  text-xl mt-20">
        {
          currPost.length > 0 && <animated.div style={animation} className={`lg:mr-16 mb-20 fadeIn  lg:max-h-[55rem]`}>
          <Link to={`/en/article/${currPost && currPost.id}`}><img
            src={
              currPost &&
              `https://admin.drmuizzu.mv${currPost && currPost.thumbnail}`
            }
            className="pl-4 pr-4 lg:pl-0 object-cover lg:max-w-[40rem] lg:min-w-[40rem] lg:max-h-[30rem] lg:min-h-[30rem] lg:pr-0"
            alt="main-content-img"
          /></Link>
          <Link to={`/en/article/${currPost && currPost.id}`}>
            <h3 className=" font-circular font-bold mb-3 lg:max-w-lg lg:max-h-[3.8rem] lg:overflow-hidden text-xl mt-5 pl-4 pr-5 lg:pl-0 lg:pr-0 text-muizzuMvDB">
              {currPost && currPost.title}
            </h3>
          </Link>
          <div className="flex flex-row">
            {/* <img className="mr-5 lg:ml-0 ml-2" src={Calender} alt="icon" /> */}
            <p className="font-poppins pl-4 pr-5 lg:pl-0 lg:pr-0 text-muizzuMvGray text-base mt-auto">
              {currPost && Moment(currPost.published_at).format('DD MMM Y')}
            </p>
          </div>
          <Link to={`/en/article/${currPost && currPost.id}`}>
            <button className="border-solid lg:ml-0 border-black ml-4 border-2 rounded-xl pl-3.5 pr-3.5 pt-2 pb-2 text-lg  lg:mt-2  mt-2 hover:bg-muizzuMvDB hover:text-white">
            More Info
            </button>
          </Link>
        </animated.div>
        }
        

        <div>
          {currPost > 0 && <h3 className="text-2xl font-poppins font-extrabold mb-5  text-muizzuMvDB pl-5 pr-5 lg:pl-0 lg:pr-0">
            Articles
          </h3>}

          <div className="overflow-scroll lg:max-h-[40rem] max-h-[40rem] scrollbarhidden">
            {loading &&
              recvPost.map((post, index) => {
                if (post.en === 1) {
                  if (
                    post.model_type === "articles" ||
                    post.model_type === "news"
                  ) {
                    return (
                      <div key={post.id} className=" lg:flex mb-8 ">
                        <Link to={url(post.model_type, post.id)}>
                          <img
                            className="mr-auto object-cover ml-auto lg:max-w-[16rem] lg:min-w-[16rem]  lg:max-h-[13rem] lg:min-h-[13rem]  min-w-[100%]  min-h-[100%] pl-4 pr-4 lg:pl-0 lg:pr-0"
                            src={`https://admin.drmuizzu.mv/${post.thumbnail}`}
                            alt="main-content-img1"
                          />
                        </Link>
                        <div>
                          <h5 className="text-base text-muizzuMvLB font-poppins mt-5 lg:mt-0 lg:ml-5 mb-3 ml-4">
                            {articletypecheck(post.model_type)}
                          </h5>
                          <Link to={url(post.model_type, post.id)}>
                            <h3 className="lg:ml-5 font-circular w-18 h-14 font-bold text-xl mb-3  pl-4 pr-5 lg:pl-0 lg:pr-0 text-muizzuMvDB">
                              {`${post.title.substr(0,45)}`}
                            </h3>
                          </Link>
                          <div className="flex flex-row mr-5">
                            {/* <img className="ml-5 " src={Calender} alt="icon" /> */}
                            <p className=" text-muizzuMvGray pl-4 pr-5 lg:pl-0 lg:pr-0 lg:ml-5 text-base mt-auto">
                              {Moment(post.published_at).format('DD MMM Y')}
                            </p>
                          </div>
                          <Link to={url(post.model_type, post.id)}>
                            <button className="border-solid border-black border-2 rounded-xl pl-2 pr-3.5 pt-2 pb-2 text-lg lg:ml-5 lg:mt-5 ml-4 mt-8 hover:bg-muizzuMvDB hover:text-white">
                              More Info
                            </button>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                } else {
                  // return console.log("No Language article published");
                }
              })}
               <div className=" flex justify-center items-center mt-5">
                    { paginate && <Link className='border-solid border-black border-2 rounded-xl px-5 py-2 font-poppins font-medium mt-5' onClick={loadMore}>Load More</Link>}
                    </div>
          </div>
          
        </div>
      </section>
    );
}