import NavbarDhi from "../Components/NavbarDhi";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";




export default function GalleryMoreInfo() {
  const [RecvData, setData] = useState();
  const [loading, setLoading] = useState(false);
  const param = useParams()


  useEffect(()=>{
    axios.get(`https://admin.drmuizzu.mv/api/gallery/${parseInt(param.id)}`).then((resp)=>{
      setData(resp.data.gallery)
      setLoading(true)
    })
  },[])

  if(!RecvData) return null;

  const zoomImg = (event) =>{
    return
    console.log(event.target.width)
    if(event.target.width < 700){
      event.target.width = 948
    }else if(event.target.width > 700){
      event.target.width = 700
    }

    // event.target.width = ;
  }
  console.log(RecvData)
  return (
    <section className='flex flex-col min-h-[100vh]'>
      <div className='pb-10 flex-1'>
      <NavbarDhi />
      <section dir="rtl" className="mr-auto ml-auto px-2 lg:px-0 w-full">
        <h3 className="font-aamu text-center mt-12 text-2xl text-muizzuMvDB">
         {RecvData.title_dv}
        </h3>
        <div className="mt-10 lg:flex lg:flex-col lg:items-center">
  

            {
              RecvData.attachments.map((attachment)=>{
                
                  if(loading && RecvData.attachments.length > 0){
                    return <div key={attachment.id} className="lg:flex lg:flex-col mb-10">
                    <div className="">
                      <img onClick={zoomImg}  className="cursor-pointer lg:max-w-[56rem] lg:min-w-[56rem] lg:max-h-[28rem] lg:min-h-[28rem] object-cover  " src={`https://admin.drmuizzu.mv/${attachment.file_url}`} alt="img" />
                    </div>
                    <div>
                      {(attachment.display_name) ?
                      <h3 className="font-faruma w-[100%] text-white bg-slate-900 bg-opacity-50 p-3 ">{attachment.display_name}</h3>
                        :
                        ''
                      }
                    </div> 
                  
                </div> 


                  }
               })
            }
            {RecvData.attachments.length === 0? <><h1 className="font-faruma text-xl">ގުޅުންހުރި އިތުރު ފޮޓޯ އެއް ނުފެނުނު</h1></> :<></>}
        </div>
      </section>
      </div>
      <div>
            <Footer/>
      </div>
    
    </section>
  )
}
