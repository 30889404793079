import HeroP from '../Assets/ArthorsPP.png'



export default function BioThaaraf(props){
   
    return(
        <section dir='rtl' className='max-w-screen-xl lg:max-h-96 mr-4 ml-4 lg:justify-center font-faruma text-xl lg:flex text-muizzuMvGray lg:mr-auto lg:ml-auto lg:mt-12 mt-12'>
            <div className='lg:max-w-2xl text-center lg:text-right pl-5 pr-5 mt-auto mb-auto'>
                <h3 className='font-aamu text-muizzuMvDB text-2xl pb-5'>ތަޢާރަފް</h3>
                <h3 className='font-aamu text-muizzuMvLB text-2xl pb-2'>{props.title}</h3>
                <p  dangerouslySetInnerHTML={{__html:props.detail}}></p>
            </div>
            <img className='lg:max-w-lg  mr-auto mt-5 object-cover' src={HeroP} alt='thaaraf-img' />
        </section>
    )
}