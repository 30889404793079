import axios from "axios"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"


const baseUrl = `https://admin.drmuizzu.mv/api/posts`

export default function DaysLifeDhi(){
    const [recvPost, setPosts] = useState()
    const [featPosts, setFeatPosts] = useState()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(4)

    const loadMore = () =>{
        setVisible((prevValue)=> prevValue + 4)
    }

    useEffect(()=>{
        async function fetchData() { 
            const request = await axios.get(baseUrl)
            setPosts(request.data.posts)
            setLoading(true)
            return request
    }
    fetchData()
    if(!recvPost){
        setLoading(false) 
    }else(setLoading(true))
    },[])


    useEffect(()=>{
        const result = () => recvPost.filter((post) => post.is_featured === 1 && post.model_type === 'life');
            if(recvPost){
                setFeatPosts(result())
                setLoading(true)
            }
            if(!featPosts){
                setLoading(false)
            }
        },[loading])

    return(
        <section dir="rtl" className="max-w-screen-xl lg:max-w-[70rem]  mb-5 lg:mr-auto lg:ml-auto font-faruma mt-20">
            <h1 className='text-center font-aamu text-2xl mb-10 text-muizzuMvDB'>ދުވަސްތައް</h1>

            <div className='flex justify-between flex-wrap'>
                {
                    featPosts && featPosts.slice(0,visible).map((post)=>{
                        return (
                            <div className="mb-20 " key={post.id}>
                               <Link to={`${post.id}`}> <img className='object-cover lg:max-w-[31rem] lg:min-w-[31rem] lg:max-h-[20rem] lg:min-h-[20rem] rounded-lg' src={`https://admin.drmuizzu.mv${post.thumbnail}`} alt="dhuvas-img1"/></Link>
                               <Link to={`${post.id}`}> <p className='text-muizzuMvGray mt-5 mr-5 lg:mr-0'>{post.published_at}</p></Link>
                               <Link to={`${post.id}`}><h3 className='text-muizzuMvDB  font-aamu text-xl tracking-wide  lg:max-w-[27rem] mt-2 mr-5 lg:mr-0'>{post.title_dv}</h3></Link>
                            </div>
                        )
                    })
                    
                }

            </div>

            <div className='lg:flex lg:justify-center mt-10'>
                <button onClick={loadMore} className='border-solid border-black border-2 rounded-xl pl-3.5 pr-3.5 pt-2 pb-2 text-lg hover:bg-muizzuMvDB hover:text-white mr-4 lg:mr-0'  >އިތުރަށް</button>
            </div>
        </section>
    )
}