import photo from '../Assets/MaleRoad.jpeg'
import NavbarDhi from '../Components/NavbarDhi'
import { useParams } from 'react-router-dom'
import { useState,useEffect } from 'react';
import axios from 'axios';
import Loading from '../Components/Loading';
import Footer from '../Components/Footer'
import Moment from 'moment';
import Calender from "../Assets/Calendar.svg"


export default function LifeMoreInfo(){
const param = useParams();
const [post, setPost] = useState()
const [loading, setLoading] = useState(false)

useEffect(()=>{
    async function fetchData() { 
        try{
            const request = await axios.get(`https://admin.drmuizzu.mv/api/posts/${param.id}`)
            setPost(await request.data.post)
            setLoading(true)
            return request

        }catch(error){
            console.log(error)
        }
         
         
 }
 fetchData()

 },[param])



if(post){
    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
            <NavbarDhi/>
       <section className="flex justify-center">
           <div dir="rtl" className="lg:max-w-screen-xl lg:flex lg:flex-col justify-center mt-12">
                    <div className='flex flex-row mb-5 mr-2 ml-auto px-2 lg:mr-0'>
                        {/* <img className='ml-2' src={Calender} alt='icon'/> */}
                        <p className='font-poppins text-muizzuMvGray text-xs mt-auto'>{Moment(post.published_at).format('Y DD MMM')}</p>
                    </div>
                    <h3 className="font-aamu text-2xl text-muizzuMvDB mb-3.5 px-2" >{post.title_dv}</h3>
                   {post.sub_title_dv && <h3 className="font-aamu mb-5 text-xl text-muizzuMvDB opacity-80 px-2 " >{post.sub_title_dv}</h3>}
               <img className="object-cover lg:max-w-[50rem] lg:min-w-[50rem] lg:max-h-[27rem] lg:min-h-[27rem] px-2" src={ `https://admin.drmuizzu.mv${post.thumbnail}`} alt="article Img"/>
               <div className="lg:max-w-[50rem]">
                   <p className="font-faruma  pt-5 px-2" dangerouslySetInnerHTML={{__html:post.body_dv}}></p>
               </div>
           </div>
       </section>
            </div>
            <div>
                <Footer/>
            </div>
       </section>
    )}
    else{
        return(<Loading/>)
    }
}