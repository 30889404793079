import NavbarEng from "../Components/NavbarEng";
import ArtcleImg from '../Assets/MaleRoad.jpeg'
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import Calender from "../Assets/Calendar.svg"
import Moment from 'moment'




export default function HomeMainContentMoreInfo(){
    const param = useParams();
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState()
    useEffect(()=>{
        axios.get(`https://admin.drmuizzu.mv/api/posts/${parseInt(param.id)}`).then((resp)=>{
            setPosts(resp.data.post)
            setLoading(true)
        })
    },[param])

    
    if(!posts) return null

let postBody = DOMPurify.sanitize(posts.body)
let postTitle = DOMPurify.sanitize(posts.title)
let postSubTitle = DOMPurify.sanitize(posts.sub_title)

    return (
            <section className='flex flex-col min-h-[100vh]'>
                <div className='pb-10 flex-1'>
                <NavbarEng/>
            <section className="lg:mr-auto lg:ml-auto">
            <div  className="lg:max-w-[50rem] lg:flex lg:flex-col items-center mt-12 mr-auto ml-auto">
                    <div className='flex flex-row mb-2 mr-auto'>
                        {/* <img className='mr-2' src={Calender} alt='icon'/> */}
                        <p className='lg:ml-2 font-poppins ml-2 text-muizzuMvGray text-base mt-auto'>{Moment(posts.published_at).format('DD MMM Y')}</p>
                    </div>
                <h3 className="text-xl px-2 mb-2 mr-auto font-bold text-muizzuMvDB " dangerouslySetInnerHTML={{__html:postTitle}}></h3>
                {postSubTitle && <h3 className=" mb-5 text-xl mr-auto text-muizzuMvDB opacity-80 px-2 " dangerouslySetInnerHTML={{__html:postSubTitle}}></h3>}
                <img className="object-cover lg:max-w-[50rem] lg:min-w-[50rem] lg:max-h-[27rem] lg:min-h-[27rem] px-2" src={`https://admin.drmuizzu.mv${posts.thumbnail}`} alt="article Img"/>
                <div className="lg:max-w-screen-xl mt-2">
                    <p className="pt-2 px-2" dangerouslySetInnerHTML={{__html:postBody}}></p>
                </div>
            </div>
        </section>
                </div>
                <div>
                    <Footer/>
                </div>
           

            </section>
        )
}