import NavbarDhi from '../Components/NavbarDhi'
import GoalsMoreInfo from '../DhiComponents/Goals-MoreInfo'

export default function IthurahVidhaalhuvey(){

    return(
            <>
            <NavbarDhi/>
            <GoalsMoreInfo/>
            </>
        )
}