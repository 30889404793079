import Dayimg1 from "../Assets/daysimg1.png";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from 'moment'


const baseUrl = `https://admin.drmuizzu.mv/api/posts`;

export default function DaysInLifeEng() {
  const [recvPost, setPosts] = useState();
  const [featPosts, setFeatPosts] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(4);

  const loadMore = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(baseUrl);
      setPosts(request.data.posts);
      setLoading(true);
      return request;
    }
    fetchData();
    if (!recvPost) {
      setLoading(false);
    } else setLoading(true);
  }, []);

  useEffect(()=>{
    const result = () => recvPost.filter((post) => post.is_featured === 1 && post.model_type === 'life');
        if(recvPost){
            setFeatPosts(result())
            setLoading(true)
        }
        if(!featPosts){
            setLoading(false)
        }
    },[loading])



  return (
    <section className="max-w-screen-xl lg:max-w-[70rem] lg:mr-auto lg:ml-auto font-poppins font-extralight mt-20">
      <h1 className="text-center font-extrabold text-2xl mb-10 text-muizzuMvDB">
        A Peek Into My History
      </h1>
      <div className='flex  justify-between flex-wrap'>
                {
                    featPosts && featPosts.slice(0,visible).map((post)=>{
                        return (
                            <div className="mb-20 px-3.5" key={post.id}>
                               <Link to={`/life/en/${post.id}`}> <img className='object-cover lg:max-w-[31rem] lg:min-w-[31rem] lg:max-h-[20rem] lg:min-h-[20rem] rounded-lg' src={`https://admin.drmuizzu.mv${post.thumbnail}`} alt="dhuvas-img1"/></Link>
                               <Link to={`/life/en/${post.id}`}><p className='text-muizzuMvGray mt-5 mr-5 lg:mr-0'>{Moment(post.published_at).format('DD MMM Y')}</p></Link>
                               <Link to={`/life/en/${post.id}`}><h3 className='font-poppins font-bold text-muizzuMvDB mt-2 mr-5 lg:max-w-[31rem] lg:mr-0'>{post.title}</h3></Link>
                            </div>
                        )
                    })
                    
                }

            </div>
            <div className='lg:flex lg:justify-center mt-10 ml-3.5'>
                <button onClick={loadMore} className='border-solid border-black border-2 rounded-xl pl-3.5 pr-3.5 pt-2 pb-2 text-lg hover:bg-muizzuMvDB hover:text-white '  >Load More</button>
            </div>
      
    </section>
  );
}
