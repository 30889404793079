import heroPP from '../Assets/HCPP.png';
import telegramIcon from "../Assets/Telegram.png"
import fbIcon  from "../Assets/Facebook.png"
import instaIcon from "../Assets/Instagram.png"
import twitterIcon from "../Assets/Twitter.png"

export default function HeroEng(props){

    return(
        <section className='max-w-screen-xl mr-2 ml-2 lg:justify-center font-poppins  text-xl lg:flex font-extralight text-muizzuMvGray lg:mr-auto lg:ml-auto lg:mt-12'>
        <img className='lg:max-w-96 lg:max-h-96 mr-auto ml-auto mb-10 lg:mb-20 pl-2 pr-2 lg:pl-0 lg:pr-0' src={heroPP} alt="hero-img"/>
            <div  className='mt-auto mb-auto lg:ml-20 pl-5 pr-5'>
                <h3 className='text-muizzuMvDB mb-5 font-poppins font-extrabold text-2xl'>{props.name}</h3>
                <p className='mb-5 font-poppins font font-extralight'>{props.text1}</p>
                <div className='flex'>
                    <a href=''><img className='pr-5' alt="social-icons" src={telegramIcon}/></a>
                    <a href=''><img className='pr-5' alt="social-icons" src={twitterIcon}/></a>
                    <a href=''><img className='pr-5' alt="social-icons" src={instaIcon}/></a>
                    <a href=''><img className='pr-5' alt="social-icons" src={fbIcon}/></a>

                </div>
            </div>
        </section>
    )
}