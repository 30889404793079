import Footer from "../Components/Footer";
import NavbarEng from "../Components/NavbarEng";
import GalleryEng from '../EngComponents/GalleryEng'


export default function ContactEng(){
    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
            <NavbarEng/>
            <GalleryEng/>
            </div>
            <div>
                <Footer/>
            </div>
        
        
        </section>
    )
}