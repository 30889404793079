import Footer from "../Components/Footer";
import Navbar from "../Components/NavbarDhi";
import BioThaaraf from "../DhiComponents/BioThaaraf";
import BioThaarafContent from "../DhiComponents/BioThaarafContent";
import UsaliDhi from "../DhiComponents/UsaliDhi";
import React, {useEffect, useState} from "react";
import axios from "axios";


const baseURL = `https://admin.drmuizzu.mv/api/home?lang=dv`

export default function BioDhi(props){
    const [RecvData, setData] = useState([]);
    const [loading, setLoading] = useState(false)


    useEffect(() => {
      axios.get(baseURL).then((resp) =>{
          setData(resp.data)
          setLoading(true)
      })
    }, []);
  
    if(!RecvData){return null}



    return (
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
                <Navbar/>
                <BioThaaraf
                    title={loading && RecvData.intro.name}
                    detail={loading && RecvData.intro.text1}
                />
                <BioThaarafContent
                    detail={loading && RecvData.intro.text2}
                />
                <UsaliDhi/>
            </div>
            <div>
                <Footer/>
            </div>
        </section>
        
    )
}