import './App.css';
import {BrowserRouter,Routes,Route, Redirect} from 'react-router-dom'
import HomeDhi from './DhiPages/HomeDhi';
import BioDhi from './DhiPages/BioDhi';
import LifeDhi from './DhiPages/LifeDhi';
import Error from './EngPages/Error';
import AlbumDhi from './DhiPages/AlbumDhi';
import ContactUsDhi from './DhiPages/ContactUsDhi';
import EnglishHome from './EngPages/HomeEng';
import BioEng from "./EngPages/BioEng.1";
import LifeEng from './EngPages/LifeEng';
import GalleryEng from './EngPages/GalleryEng'
import ContactEng from './EngPages/ContactEng'
import MoreInfo from './DhiPages/IthurahVidhaalhu'
import GalleryMoreInfo from './DhiComponents/GalleryMoreInfo';
import ArticleMoreInfoDhi from './DhiComponents/ArticleMoreInfoDhi';
import GoalsMoreInforEng from './EngComponents/GoalsMoreInfoEng';
import GalleryMoreInfoEng from './EngComponents/GalleryEngMoreInfo'
import HomeMainContentMoreInfo from './EngComponents/HomeMainContentMoreInfo';
import NewsDv from './DhiPages/NewsDv';
import NewsMoreInfo from './DhiComponents/NewsMoreinfo';
import GoalsEng from './EngComponents/GoalsEng';
import News from './EngPages/News';
import NewsMoreInfoEn from './EngComponents/NewsMoreInfoEn'
import LifeMoreInfo from './DhiComponents/LifeMoreInfo';
import LifeMoreInfoEng from './EngComponents/LifeMoreInfoEng';


function App() {
  return (
    <>
    {/* <div className='max-w-screen-xl hidden lg:block lg:mt-0 lg:mb-0 lg:ml-5 '><a className='text-muizzuMvDB' href=''>English</a></div> */}

    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomeDhi/>}/>
        <Route path='/bio' element={<BioDhi/>} />
        <Route path='/life'>
          <Route index element={<LifeDhi/>} />
          <Route path=':id' element={<LifeMoreInfo/>}/>
        </Route>
        <Route path='/contactUs' element={<ContactUsDhi/>} />
        <Route path='/gallery' element={<AlbumDhi/>} />
        <Route path='/gallery/:id' element={<GalleryMoreInfo/>}/>
        <Route path='/article/:id' element={<ArticleMoreInfoDhi/>}/>
        <Route path='/news/:id' element={<ArticleMoreInfoDhi/>}/>
        {/* <Route path='/life/:id' element={<ArticleMoreInfoDhi/>}/> */}
        <Route path='/article/:id' element={<ArticleMoreInfoDhi/>}/>
        <Route path='/en' element={<EnglishHome/>}/>
        <Route path='/bio/en' element={<BioEng/>}/>
        <Route path='/life/en'>
          <Route index element={<LifeEng/>} />
          <Route path=':id' element={<LifeMoreInfoEng/>}/>
        </Route>
        <Route path='/gallery/en' element={<GalleryEng/>}/>
        <Route path='/eng-contact' element={<ContactEng/>}/>
        <Route path='/en/:name' element={<GoalsMoreInforEng/>}/>
        <Route path='/en/article/:id' element={<HomeMainContentMoreInfo/>}/>
        <Route path='/gallery/en/:id' element={<GalleryMoreInfoEng/>}/>
        <Route path=':name' element={<MoreInfo/>}/>
        <Route path='/articles'>
          <Route index element={<NewsDv/>}/>
          <Route path=':id' element={<NewsMoreInfo/>} />
        </Route>
        <Route path='/articles/en' >
          <Route index element={<News/>}/>
          <Route path=':id' element={<NewsMoreInfoEn/>}/>
        </Route>

        <Route path='*' element={<Error/>} />
      </Routes>
    </BrowserRouter>
    </>
  )
}

export default App;
