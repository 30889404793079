
export default function Footer(){

    return(
        
        <footer className="bg-[#232d35] lg:mt-10 text-center  w-full  h-16 text-white flex">
            <p className="mt-auto mb-auto mr-auto ml-auto">Copyright © 2023 Dr Mohamed Muizzu. All Rights Reserved</p>
        </footer>
        
        
    )
}