import NavbarDhi from "../Components/NavbarDhi";
import ArtcleImg from '../Assets/MaleRoad.jpeg'
import Calender from "../Assets/Calendar.svg"
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import Moment from 'moment'



export default function(){
    const param = useParams();
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState()
    useEffect(()=>{
        axios.get(`https://admin.drmuizzu.mv/api/posts/${parseInt(param.id)}`).then((resp)=>{
            setPosts(resp.data.post)
            setLoading(true)
        })
    },[param])

    
    if(!posts) return null

let postBody = DOMPurify.sanitize(posts.body_dv)
let postTitle = DOMPurify.sanitize(posts.title_dv)
let postSubTitle = DOMPurify.sanitize(posts.sub_title_dv)


    return (
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
            <NavbarDhi/>
            <section className="flex justify-center">
            <div dir="rtl" className="lg:max-w-[50rem] items-center lg:flex lg:flex-col mt-12">
                    <div className='flex flex-row mb-5 mr-2 ml-auto lg:mr-0 px-2'>
                        {/* <img className='ml-3.5 ' src={Calender} alt='icon'/> */}
                        <p dir="ltr" className='font-poppins text-muizzuMvGray text-xs mt-auto'>{Moment(posts.published_at).format('DD MMM YYYY')}</p>
                    </div>
                <h3 className="font-aamu text-[1.920rem] leading-10 ml-auto mb-3.5 text-muizzuMvDB px-4 lg:px-0" dangerouslySetInnerHTML={{__html:postTitle}}></h3>
                {postSubTitle && <h3 className="font-aamu mb-5 ml-auto text-xl text-muizzuMvDB opacity-80 px-4 lg:px-0 " dangerouslySetInnerHTML={{__html:postSubTitle}}></h3>}
                <img className="object-cover lg:max-w-[50rem] lg:min-w-[50rem] lg:max-h-[27rem] lg:min-h-[27rem] min-w-full px-4 lg:px-0" src={`https://admin.drmuizzu.mv${posts.thumbnail}`} alt="article Img"/>
                <div className="lg:max-w-3xl ml-auto mt-2 font-faseyha text-[1.311rem] text-[#333333] leading-10 pt-5 px-4 lg:px-0"dangerouslySetInnerHTML={{__html:postBody}}>
                    
                </div>
            </div>
        </section>
            </div>
            <div>
                <Footer/>
            </div>
        </section>

    )
}