import { Disclosure} from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from "react-router-dom"
import logo from '../Assets/logo.png'
import DhivehiLogo from '../Assets/maldives.png'


export default function NavbarEng() {


const activeStyles = 'mt-auto mb-auto ml-16 hover:text-muizzuMvLB text-muizzuMvLB';
const inActiveStyles = 'mt-auto mb-auto ml-16 hover:text-muizzuMvLB ';
const mobActiveStyles = 'mr-auto ml-auto mt-3 text-muizzuMvLB underline';
const mobInActiveStyles = 'mr-auto ml-auto mt-3';

  return (
    <Disclosure as="nav" className="bg-white-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">

                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden md:hidden sm:hidden  w-20"
                    src={logo}
                    alt="Workflow"
                  />
                </div>
                {/* Big Screen Menu Items*/}
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex">
                    
                  <nav className="font-circular mt-2 text-muizzuMvDB  text-xl flex justify-center">
                        <NavLink to='/en' className={({isActive})=>isActive?activeStyles:inActiveStyles}>Home</NavLink>
                        {/* <NavLink to='/bio/en' className={({isActive})=>isActive?activeStyles:inActiveStyles}>Biography</NavLink> */}
                        {/* <NavLink to='/life/en' className={({isActive})=>isActive?activeStyles:inActiveStyles}>Life</NavLink> */}
                        <NavLink to='/gallery/en' className={({isActive})=>isActive?activeStyles:inActiveStyles}>Gallery</NavLink>
                        <NavLink to='/articles/en' className={({isActive})=>isActive?activeStyles:inActiveStyles}>Articles</NavLink>
                        {/* <NavLink to='/eng-contact' className={({isActive})=>isActive?activeStyles:inActiveStyles}>ގުޅުއްވުމަށް</NavLink> */}
                        <NavLink to='/' className='mt-auto mb-auto ml-16' ><img className='max-h-5' src={DhivehiLogo}/></NavLink>
                        <NavLink to='/en' className=''><img className="w-20 ml-10 " src={logo} alt='DesktopLogo'/></NavLink>


                  </nav>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
                    {/* Mobile menu button*/}
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
            <nav className="flex flex-col font-circular text-muizzuMvDB mt-2 text-xl">
                <NavLink to='/en' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>Home</NavLink>
                {/* <NavLink to='/bio/en' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>Biography</NavLink> */}
                {/* <NavLink to='/life/en' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>Life</NavLink> */}
                <NavLink to='/gallery/en' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>Gallery</NavLink>
                <NavLink to='/articles/en' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>Articles</NavLink>
                <NavLink to='/' className='mt-5' ><img className='max-h-6 mr-auto ml-auto ' src={DhivehiLogo}/></NavLink>

                {/* <NavLink to='/eng-contact' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>ގުޅުއްވުމަށް</NavLink> */}
        </nav>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
