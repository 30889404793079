import { useState, useEffect } from "react"
import Calender from "../Assets/Calendar.svg"
import CameraIcon from "../Assets/Camera.svg"
import axios from "axios"
import {Link } from "react-router-dom"
import Moment from 'moment'
import Loading from "../Components/Loading";

const baseURL = `https://admin.drmuizzu.mv/api/home?`

export default function GalleryDhi(){

    const [RecvData, setData] = useState();
    const [loading, setLoading] = useState(false)


    useEffect(()=>{
        async function fetchData() { 
            const request = await axios.get(baseURL)
            setData(request.data.galleries)
            setLoading(true)
            return request
    }
    fetchData()
    },[])
  

if(loading){
 return (
    <section dir='rtl' className='max-w-screen-xl lg:mr-auto lg:ml-auto font-aamu mt-5 mb-20'>
        <h1 className='font-aamu text-muizzuMvDB text-3xl text-center mt-9 mb-9 '>ގެލެރީ</h1>
        <div className=' lg:flex lg:flex-wrap lg:gap-16 px-3 lg:px-0'>

            {
                RecvData.map((gallery)=>{
                    if(gallery.status === 'published'){
                    return <div key={gallery.id} className='lg:mt-5 mb-16'>
                    <Link to={`/gallery/${gallery.id}`}><img className='lg:pr-0 object-cover lg:pl-0 lg:max-w-[24rem] lg:min-w-[24rem] lg:max-h-[18rem] lg:min-h-[18rem]' src={`https://admin.drmuizzu.mv${gallery.thumbnail}`} alt='gallery-img'/></Link>
                    <Link to={`/gallery/${gallery.id}`}><h3 className='text-xl lg:max-w-[24rem] lg:min-w-[24rem] text-muizzuMvDB mt-5 lg:mr-0 '>{gallery.title_dv}</h3></Link>
                    <div className='flex flex-row mt-2 lg:mr-0'>
                        <img className='w-6 ' src={CameraIcon} alt='icons'/>
                        <p className='mt-1.5 mr-2 ml-5  font-poppins text-[0.8rem] text-muizzuMvGray' >{gallery.photo_count}</p>
                        <img className="w-6 mt-[0.1rem]" src={Calender} alt='icons'/>
                        <p dir="ltr" className='mt-1.5 mr-2 text-[0.8rem] font-poppins text-muizzuMvGray'>{ Moment(gallery.created_at.substr(0,10)).format('DD MMM YYYY')}</p>
                    </div>
                </div>}
                }).reverse()
            
            }
        </div>
    </section>
)
}else{
    return (<Loading/>)
}
    
}

const styles = {
    icon: {
        top: 10
    }
}