import testImg from '../Assets/MaleRoad.jpeg'
import Calender from "../Assets/Calendar.svg"
import { Link } from 'react-router-dom';
import Moment from 'moment';
import axios from "axios"
import { useState, useEffect } from "react"
import { type } from "@testing-library/user-event/dist/type"
import Loading from "../Components/Loading";

const baseUrl = `https://admin.drmuizzu.mv/api/posts`


export default function NewsInfo(){
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        async function fetchData() { 
            const request = await axios.get(baseUrl)
            setPosts(request.data.posts)
            setLoading(true)
            return request
    }
    fetchData()
    },[])
    if(loading){
        return (
            <>
            <h1 className='font-aamu lg:hidden mt-5 text-muizzuMvDB text-3xl text-center'>ލިޔުންތައް</h1>
          <section dir='rtl' className="max-w-screen-xl flex justify-evenly flex-wrap lg:mr-auto lg:ml-auto mt-10">
          
            {
                
                loading && posts.map((post)=>{
                   
                    if(post.model_type === "news" || post.model_type === "articles"){
                        return (
                        <div key={post.id} className="w-full rounded-lg  lg:max-w-sm lg:mb-[5rem] mb-[1rem]">
                            <Link  to={`${post.id}`}><img
                                className="object-cover w-full px-3.5 lg:max-w-[21rem] lg:min-w-[21rem] lg:max-h-[13rem] lg:min-h-[13rem]lg:pl-0 lg:pr-0 h-52"
                                src={`https://admin.drmuizzu.mv${post.thumbnail}`}
                                alt="image"
                            /></Link>
                            <div className='flex flex-row mt-3.5 mb-2'>
                                        {/* <img className='ml-5 ' src={Calender} alt='icon'/> */}
                                        <p dir="ltr" className=' text-muizzuMvGray font-poppins mr-4 lg:mr-0 text-[0.85rem] mt-auto'>{Moment(post.published_at).format('DD MMM YYYY')}</p>
                                    </div>
                            <div className="">
                            <Link  to={`${post.id}`}><h4 className=" text-ellipsis pl-3.5 mr-4 lg:mr-0 text-xl tracking-wide lg:max-w-[22rem] lg:min-h-[4rem] font-aamu text-muizzuMvDB">
                                {
                                post.title_dv 
                                /* {`${post.title_dv.slice(0,90)}...`} */
                                }
                                </h4></Link>
                                {/* <Link className='visible lg:invisible'  to={`${post.id}`}><h4 className="visible lg:invisible text-xl tracking-wide lg:max-w-[22rem] lg:min-h-[4rem] font-aamu text-muizzuMvDB">
                                {post.title_dv}
                                </h4></Link> need full title on mobile view */}
                        
                                <Link  to={`${post.id}`}><button className='invisible lg:visible border-solid font-faruma border-black border-2 rounded-xl pl-3.5 pr-3.5 pt-2 pb-2 text-lg  hover:bg-muizzuMvDB mt-2 hover:text-white'>އިތުރަށް ވިދާޅުވުމަށް ←</button></Link>
                            </div>
                        </div>
                        )
                    }
                })
            }
    
           
          </section>
          </>
        );
    }else{
        return (<Loading/>)
    }



}