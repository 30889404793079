import NavbarEng from "../Components/NavbarEng";
import Achievements from "../EngComponents/Achievements";
import BioIntroEng from "../EngComponents/BioIntroEng";
import BioIntroMainContent from "../EngComponents/BioIntroMainContent";
import Footer from '../Components/Footer'
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loading from '../Components/Loading'

const baseURL = `https://admin.drmuizzu.mv/api/home?lang=en`


export default function BioEng() {
    const [RecvData, setData] = useState([]);
    const [loading, setLoading] = useState(false)


    useEffect(() => {
      axios.get(baseURL).then((resp) =>{
          setData(resp.data)
          setLoading(true)
      })
    }, []);
  
 
    if(RecvData){
        return (
            <>
                <NavbarEng />
                <BioIntroEng
                    title={loading && RecvData.intro.name_en}
                    detail={loading && RecvData.intro.text1_en}
                />
                <BioIntroMainContent
                    detail={loading && RecvData.intro.text2_en}
                />
                <Achievements/>
                <Footer/>
            </>
        )
    }
    else{
        return (<Loading/>)
    }
 
}
