import NavbarEng from "../Components/NavbarEng";
import GalleryEngHome from "../EngComponents/GalleryEngHome";
import GoalsEng from "../EngComponents/GoalsEng";
import HeroEng from '../EngComponents/HeroEng'
import MainHomeContentEng from "../EngComponents/HomeMainContentEng";
import Footer from '../Components/Footer'
import React,{ useState,useEffect } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import Loading from '../Components/Loading'
import TweetsEn from "../EngComponents/TweetsEn";

const baseURL = `https://admin.drmuizzu.mv/api/home`


export default function HomeEng(){
    const [RecvData, setData] = useState();
    const [RecvMainData, setMainData] = useState();
    
    useEffect(() => {
        axios.get(baseURL).then((resp) =>{
            setData(resp.data)
            setMainData(resp.data.posts)
        })
      }, []);



   
if(RecvData){
    let pureForesight = DOMPurify.sanitize(RecvData.intro.foresight_en_ex)
    let pureViews = DOMPurify.sanitize(RecvData.intro.views_en_ex)
    let purePolicy = DOMPurify.sanitize(RecvData.intro.policy_en_ex)
    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
            <NavbarEng/>
            <HeroEng
            name={RecvData.intro.name_en}
            text1={RecvData.intro.text1_en}
        />
        {/* <GoalsEng
            foresight ={pureForesight}
            views = {pureViews}
            policy ={purePolicy}
        /> */}
        <MainHomeContentEng
        posts={RecvMainData}
        />
        <TweetsEn/>
        <GalleryEngHome/>
            </div>
            <div>
        <Footer/>
            </div>

        </section>
    )
}else{
    return (<Loading/>)
}

}