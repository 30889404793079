import { Fragment } from "react"
import Footer from "../Components/Footer"
import NavbarDhi from "../Components/NavbarDhi"
import NewsInfo from "../DhiComponents/NewsInfo"

export default function NewsDv(){
    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
                <NavbarDhi/>
                <NewsInfo/>
            </div>
            <div className=''>
                <Footer/>
            </div>
        </section>
    )
}