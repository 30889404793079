
import heroPP from '../Assets/HCPP.png';
import telegramIcon from "../Assets/Telegram.png"
import fbIcon  from "../Assets/Facebook.png"
import instaIcon from "../Assets/Instagram.png"
import twitterIcon from "../Assets/Twitter.png"


export default function HeroDhi(props){
    return(
        <section className='max-w-screen-xl mr-4 ml-4 lg:justify-center font-faruma text-xl lg:flex text-muizzuMvGray lg:mr-auto lg:ml-auto lg:mt-12'>
        <img  className='object-cover lg:max-w-96 lg:max-h-96 mr-auto ml-auto mb-10 lg:mb-20  lg:pl-0 lg:pr-0' src={heroPP} alt="hero-img"/>
            <div dir='rtl' className='mt-auto mb-auto lg:ml-20 pl-5 pr-5'>
                <h3 className='text-muizzuMvDB mb-5 font-aamu text-[1.877rem]'>{props.name}</h3>
                <p className='text-[1.4rem] leading-10 mb-5 text-[#555]'>{props.text1}</p>
                <div className='flex'>
                    <a href=''><img className='pl-5' alt="social-icons" src={telegramIcon}/></a>
                    <a href=''><img className='pl-5' alt="social-icons" src={twitterIcon}/></a>
                    <a href=''><img className='pl-5' alt="social-icons" src={instaIcon}/></a>
                    <a href=''><img className='pl-5' alt="social-icons" src={fbIcon}/></a>

                </div>
            </div>
        </section>
    )
}