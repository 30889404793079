import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const baseURL = `https://admin.drmuizzu.mv/api/home`;

export default function GoalsMoreInfo(props) {
  const [RecvData, setData] = useState();
  const [RecvMorePhoto, setMorePhoto] = useState();
  const [currentImgIndex, setCurrentImgIndex] = useState();
  const [currentImgTitle, setCurrentImgTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [goalInfo, setGoalInfo] = useState();
  const [dynTitle, setDynTitle] = useState();
  const [currGoal, setGoal] = useState();
  const param = useParams();

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(baseURL);
      setData(request.data);
      return request;
    }
    fetchData();
    if (!RecvData) {
      setLoading(false);
    } else setLoading(true);

    axios
      .get(`https://admin.drmuizzu.mv/api/sliders?type=${param.name}`)
      .then((resp) => {
        setMorePhoto(resp.data.sliders);
      });
  }, []);

  useEffect(() => {

    const findTitle = () => {
      let newTitle = "";
      if (param.name === "policy") {
        newTitle = "އަޅުގަނޑުގެ ސިޔާސަތު";
        setDynTitle(newTitle);
      } else if (param.name === "views") {
        newTitle = "އަޅުގަނޑުގެ ތަސައްވަރު";
        setDynTitle(newTitle);
      } else if (param.name === "foresight") {
        newTitle = "އަޅުގަނޑުގެ މިސްރާބް";
        setDynTitle(newTitle);
      }
    };

    const goalInfo =()=>{
      if(RecvData && param.name === "policy"){
        setGoal(RecvData.intro.policy)
      }
      else if (RecvData && param.name === "views")
      {
        setGoal(RecvData.intro.views)
      }
      else if(RecvData && param.name === "foresight"){
        setGoal(RecvData.intro.foresight)
      }
    }

    goalInfo()
    findTitle();

  }, [RecvData]);

  console.log(param);

  if (!RecvData) return null;
  if (!RecvMorePhoto) return null;



  return (
    <section className='flex flex-col min-h-[100vh]'>
      <div className='pb-5 flex-1'>
      <div
        dir="rtl"
        className="max-w-screen-xl mt-12 font-faruma lg:mr-auto lg:ml-auto"
      >
        <h3 className="font-aamu text-muizzuMvDB text-2xl mb-5 text text-center ">
          {dynTitle}
        </h3>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 4500 }}
          navigation
          onSlideChange={(swiper) =>
            setCurrentImgIndex(swiper.imagesToLoad[swiper.activeIndex].id)
          }
          onSwiper={(swiper) =>
            setCurrentImgIndex(swiper.imagesToLoad[swiper.activeIndex].id)
          }
          className="lg:max-w-[55rem] lg:min-w-[55rem] lg:max-h-[30rem] lg:min-h-[30rem] px-4 lg:px-0 object-cover"
        >
          {RecvMorePhoto.map((element) => {
            if (element.status === "active") {
              return (
                <SwiperSlide key={element.id}>
                  <img
                    id={element.id && 1}
                    key={element.id}
                    src={`https://admin.drmuizzu.mv${element.thumbnail}`}
                    alt="section-img"
                  />
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
        
        
        <div className='flex lg:max-w-[55rem] lg:mr-auto lg:ml-auto px-4 lg:px-0 mt-5'>
          <p className="text-right lg:text-right font-faruma text-xl lg:pr-0 lg:pl-0 " dangerouslySetInnerHTML={{__html:currGoal && currGoal}}></p>
        </div>
          
      </div>
      </div>
      <div>
        <Footer/>
      </div>
      
    </section>
  );
}
