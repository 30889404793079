import photo from '../Assets/MaleRoad.jpeg'
import NavbarEng from '../Components/NavbarEng'
import { useParams } from 'react-router-dom'
import { useState,useEffect } from 'react';
import axios from 'axios';
import Loading from '../Components/Loading'
import Footer from '../Components/Footer';
import Moment from 'moment'


export default function LifeMoreInfoEng(){//
    const param = useParams();
    const [post, setPost] = useState()
    const [loading, setLoading] = useState(false)
    
    useEffect(()=>{
        async function fetchData() { 
            try{
                const request = await axios.get(`https://admin.drmuizzu.mv/api/posts/${param.id}`)
                setPost(await request.data.post)
                setLoading(true)
                return request
    
            }catch(error){
                console.log(error)
            }
             
             
     }
     fetchData()
    
     },[param])

     if(post){

     
    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
        <NavbarEng/>
            <section className="flex justify-center">
        <div  className="lg:max-w-[50rem] lg:flex lg:flex-col items-left mt-12 mr-auto ml-auto">
                <div className='flex flex-row mb-2 mr-auto'>
                        {/* <img className='mr-2' src={Calender} alt='icon'/> */}
                        <p className='lg:ml-2 font-poppins text-muizzuMvGray text-base mt-auto'>{Moment(post.published_at).format('DD MMM Y')}</p>
                </div>
            <h3 className="text-xl px-2 mb-2  mr-auto font-bold text-muizzuMvDB " dangerouslySetInnerHTML={{__html:post.title}}></h3>
            {post.sub_title && <h3 className=" mb-5 text-xl mr-auto text-muizzuMvDB opacity-80 px-2 " dangerouslySetInnerHTML={{__html:post.sub_title}}></h3>}
            <img className="object-cover lg:max-w-[50rem] lg:min-w-[50rem] lg:max-h-[27rem] lg:min-h-[27rem] px-2" src={ `https://admin.drmuizzu.mv${post.thumbnail}`} alt="article Img"/>
            <div className="lg:max-w-screen-xl">
                <p className="pt-5 px-2" dangerouslySetInnerHTML={{__html:post.body}}></p>
            </div>
        </div>
    </section>
            </div>
            <div>
                <Footer/>
            </div>

 
        </section>
    )}
    else{
        return(<Loading/>)
    }
}