import Footer from "../Components/Footer";
import Navbar from "../Components/NavbarDhi";
import GalleryDhi from "../DhiComponents/GalleryDhi";


export default function AlbumDhi(){

    return(
        <section className='flex flex-col min-h-[100vh]'>
            <div className='pb-10 flex-1'>
                <Navbar/>
                <GalleryDhi/>
            </div>
            <div>
                <Footer/>
            </div>
       
        </section>
    )
}