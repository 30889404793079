import { Disclosure} from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from "react-router-dom"
import logo from '../Assets/logo.png'
import EnglishLogo from '../Assets/united-kingdom.png'


export default function NavbarDhi() {


const activeStyles = 'mt-auto mb-auto ml-16 hover:text-muizzuMvLB text-muizzuMvLB';
const inActiveStyles = 'mt-auto mb-auto ml-16 hover:text-muizzuMvLB';
const mobActiveStyles = 'mr-auto ml-auto mt-3 text-muizzuMvLB underline';
const mobInActiveStyles = 'mr-auto ml-auto mt-3';

  return (
    <Disclosure as="nav" className="bg-white-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">

                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch">
                <div className="flex-shrink-0 flex items-center">
                  <a href='/'>
                    <img
                      className="block lg:hidden md:hidden sm:hidden  w-20"
                      src={logo}
                      alt="Workflow"
                    />
                  </a>
                </div>
                {/* Big Screen Menu Items*/}
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex">
                    
                  <nav dir="rtl" className="font-aamu mt-2 text-muizzuMvDB  text-xl flex justify-center">
                        <NavLink to='/' className=''><img className="w-20 ml-32 " src={logo} alt='DesktopLogo'/></NavLink>
                        <NavLink to='/' end className={({isActive})=>isActive?activeStyles:inActiveStyles}>ހޯމް</NavLink>
                        {/* <NavLink to='/bio' className={({isActive})=>isActive?activeStyles:inActiveStyles}>ތަޢާރަފް</NavLink> */}
                        {/* <NavLink to='/life' className={({isActive})=>isActive?activeStyles:inActiveStyles}>ހަޔާތް</NavLink> */}
                        <NavLink to='/gallery' className={({isActive})=>isActive?activeStyles:inActiveStyles}>ގެލެރީ</NavLink>
                        <NavLink to='/articles' className={({isActive})=>isActive?activeStyles:inActiveStyles}>ލިޔުންތައް</NavLink>
                        {/* <NavLink to='/contactUs' className={({isActive})=>isActive?activeStyles:inActiveStyles}>ގުޅުއްވުމަށް</NavLink> */}
                        <NavLink to='/en' className='mt-auto mb-auto ml-16' ><img className='max-h-5' src={EnglishLogo}/></NavLink>
                        
                  </nav>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
                    {/* Mobile menu button*/}
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
            <nav className="flex flex-col font-aamu text-muizzuMvDB mt-2 text-xl">
                <NavLink to='/' end className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>ހޯމް</NavLink>
                {/* <NavLink to='/bio' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>ތަޢާރަފް</NavLink> */}
                {/* <NavLink to='/life' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>ހަޔާތް</NavLink> */}
                <NavLink to='/gallery' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>ގެލެރީ</NavLink>
                <NavLink to='/articles' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>ލިޔުންތައް</NavLink>
                <NavLink to='/en' className='mt-5' ><img className='max-h-6 mr-auto ml-auto ' src={EnglishLogo}/></NavLink>
                {/* <NavLink to='/contactUs' className={({isActive})=>isActive?mobActiveStyles:mobInActiveStyles}>ގުޅުއްވުމަށް</NavLink> */}
        </nav>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
