import React, { useState, useEffect } from "react";
import HeroDhi from "../DhiComponents/HeroDhi";
import Navbar from "../Components/NavbarDhi";
import MainContentDhi from "../DhiComponents/MainContentDhi";
import GalleryDhiHome from '../DhiComponents/GalleryDhiHome'
import Footer from "../Components/Footer";
import axios from "axios";
import DOMPurify from "dompurify";
import Loading from "../Components/Loading";
import Timeline from "../DhiComponents/Tweets"

const baseURL = `https://admin.drmuizzu.mv/api/home`


export default function HomeDhi() {
  const [RecvData, setData] = useState();
  const [RecvMainData, setMainData] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchData() { 
      try{
          const request = await axios.get(baseURL)
          setData(await request.data)
          setMainData(await request.data.posts)
          setLoading(true)
          return request

      }catch(error){
          console.log(error)
      }
       
       
    }
      fetchData()

    }, []);


  if(loading){
  let pureForesight = DOMPurify.sanitize(RecvData.intro.foresight_ex)
  let pureViews = DOMPurify.sanitize(RecvData.intro.views_ex)
  let purePolicy = DOMPurify.sanitize(RecvData.intro.policy_ex)
  return (
    <section className='flex flex-col min-h-[100vh]'>
      <div >
        <Navbar />
        <HeroDhi
          name={RecvData.intro.name}
          text1={RecvData.intro.text1}
          />
        {/* <GoalsDhi 
          foresight ={pureForesight}
          views = {pureViews}
          policy ={purePolicy}
        /> */}
      
        <MainContentDhi
        posts={RecvMainData}
        />
        <Timeline/>
        <GalleryDhiHome/>
      </div>
      <div>
        <Footer />
      </div>

      
    </section>
  );
  }
  else{
    return (<Loading/>)
  }
}
