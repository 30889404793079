import { useState, useEffect } from "react"
import Calender from "../Assets/Calendar.svg"
import CameraIcon from "../Assets/Camera.svg"
import GalleryIMG1  from "../Assets/GalleryAssets/Rectangle 114.png"
import axios from "axios"
import {Link } from "react-router-dom"
import Moment from 'moment'


const baseURL = `https://admin.drmuizzu.mv/api/home?`

export default function GalleryEngHome(){

    const [RecvData, setData] = useState();

    useEffect(() => {
      axios.get(baseURL).then((resp) =>{

          setData(resp.data.galleries)
      })
    }, []);
  
    if(!RecvData) return null;

    return (
        <section className='max-w-screen-xl lg:mr-auto lg:ml-auto font-poppins font-extralight mt-20'>
            <h1 className='font-poppins font-extrabold text-2xl text-center text-muizzuMvDB'>Gallery</h1>
            
            <div className='mt-5 lg:flex lg:flex-wrap lg:gap-16'>

            {
                    RecvData.map((gallery)=>{
                        if(gallery.status === 'published' && gallery.is_featured){
                        return <div key={gallery.id} className='lg:mt-5 mt-16'>
                        <Link to={`/gallery/en/${gallery.id}`}><img className='w-full pr-4 pl-4 lg:pr-0 object-cover lg:pl-0 lg:max-w-[24rem] lg:min-w-[24rem] lg:max-h-[18rem] lg:min-h-[18rem]' src={`https://admin.drmuizzu.mv${gallery.thumbnail}`} alt='gallery-img'/></Link>
                        <Link to={`/gallery/en/${gallery.id}`}><h3 className='text-xl mb-2 text-muizzuMvDB lg:max-w-[24rem] lg:min-w-[24rem] font-normal mt-5 lg:pl-0  pl-4'>{gallery.title}</h3></Link>
                        <div className='flex flex-row ml-4 lg:ml-0'>
                            <img className='w-[2rem]' src={CameraIcon} alt='icons'/>
                            <p className='mt-auto mb-auto mr-2 ml-3 text-base items-center text-muizzuMvGray'>{gallery.photo_count}</p>
                            <img className="ml-3" src={Calender} alt='icons'/>
                            <p className='mt-auto mb-auto mr-2 text-base ml-3 text-muizzuMvGray'>{Moment(gallery.created_at.substr(0,10)).format('DD MMM Y')}</p>
                        </div>
                    </div>
                    }}).reverse()
                
                }


            </div>
        </section>
    )
}