import Comma from '../Assets/“.svg'



export default function BioIntroMainContent(props){

    return (
        <section className="max-w-screen-xl lg:flex  lg:mr-auto lg:ml-auto flex flex-col items-center font-poppins text-xl mt-20" >
        {/* <div className='lg:mr-auto lg:ml-auto pl-5 pr-5'>
            <img className='rotate-180' src={Comma} alt='quote-comma-img'/>
            <p className='lg:max-w-3xl leading-10 lg:pr-10 font-poppins font-extrabold text-2xl text-center text-muizzuMvDB'>Dr. Mohamed Muizzu entered the history books in 2021 for winning male city mayor</p>
        </div> */}
        <div className='mt-5 pr-5 pl-5'>
            <p className='lg:max-w-5xl text-center text-muizzuMvGray' dangerouslySetInnerHTML={{__html:props.detail}}></p>
        </div>
    </section>
    )
}